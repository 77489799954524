.App {
  text-align: center;
}

.App-logo-page {
  height: 14vmin;
  pointer-events: none;
  position: fixed;
  top: 70px;
  /* position the top  edge of the element at the middle of the parent */
  left: 50%;
  /* position the left edge of the element at the middle of the parent */
  transform: translate(-50%, 20%);
  height: 35em;
  width: 25em;
  opacity: 0.4;
  filter: blur(10px);
  -webkit-filter: blur(10px);
}

.img-container {
  text-align: center;
}

.map {
  border: none;
  border: solid 3px rgb(82, 46, 16);
  width: 100%;
  min-height: 450px;
}

.sidebar {
  position: fixed;
  top: 25%;
  right: 2%;
  border: burlywood 1px solid;
  border-radius: 5px;
  height: 40vh;
}
