.navbar ul li a {
    min-height: 30px;
    background-color: #473324;
    font-weight: bold;
    font-size: 0.7em;
    text-transform: uppercase;
    margin-left: 10px;
    margin-right: 10px;
}

.navbar ul li a:focus {
    color: whitesmoke !important;
}

.navbar ul li a:active {
    color: greenyellow !important;
}
