.App-header {
  background-color: #473324;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 1vmin);
  color: white;
  border-bottom: antiquewhite 2px solid;
  min-height: 80px;
}

.container.header {
  width: 55%;
  /* padding: 15; */
}

.header-logo {
  max-height: 55px;
  min-width: 40px;
  filter: brightness(100);
  /* border: aliceblue solid 1px; */
  /* border-radius: 5px; */
  /* padding: 2%; */
}

.navbar {
  text-align: right;
}

.navbar li {
  display: inline-block;
  margin-right: 1 em;
}
