body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #e2c68e;
  color: #523d2e;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  color: whitesmoke;
  background-color: gray;
}

.container.page {
  min-height: 70vh;
  width: 55%;
  text-align: left;
  border-left: burlywood solid 1px;
  border-right: burlywood solid 1px;
  padding: 0;
}

.page-section {
  margin: 0;
  display: unset;
}

.row {
  margin: auto;
}

/* ======================= Text styles ========================== */

h1 {
  font-size: calc(0.5em + 2.8vw);
  margin-bottom: calc(0.2em + 1vh);
  width: 100%;
  letter-spacing: .4rem;
  text-transform: uppercase;
  opacity: .4;
}

h2 {
  font-size: 1.7vw;
  font-weight: bold;
  font-variant-caps: all-petite-caps;
  text-transform: uppercase;
  color: rgb(97, 104, 54);
  margin-bottom: 20px;
}

p, .text {
  font-family: 'Times New Roman', Times, serif;
  font-weight: bold;
  font-size: calc(0.1em + 0.9vw);
  color: rgb(65, 37, 6);
  text-align: justify;
}

a {
  color: rgb(65, 37, 6);
}

a:hover {
  /* color: rgb(155, 207, 95); */
}

@media (max-width: 800px) {
  .container.page {
    width: 100%;
  }

  .App-footer {
    width: 100%;
  }
}
