.App-footer {
  min-height: 15vh;
  text-align: left;
  width: 100%;
  margin: auto;
  padding-top: 20px;
  padding-bottom: 20px;
  border-bottom: solid 15px rgba(65, 37, 6, 0.8);
  border-top: solid 1px wheat;
  background-color: rgba(178, 168, 50, 0.2);
}

.App-footer img {
  max-height: 130px;
  width: auto;
}

.nav-link {
  padding: .25rem;
}

.kapcsolat {
  text-align: right;
}
